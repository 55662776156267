import { RedirectRoutes } from 'logging/analytics/events/pageViews';
import { Route } from 'store/routing/routes';

export const PageTitle: Record<Exclude<Route, RedirectRoutes>, string> = {
  [Route.HOME]: 'eblock',

  [Route.ACCOUNT]: 'my_account',
  [Route.ACCOUNT_COMPANIES]: 'companies',
  [Route.ACCOUNT_DELETE_ACCOUNT]: 'delete_account',

  [Route.ACKNOWLEDGEMENTS]: 'acknowledgements',

  [Route.ADMIN_ARBITRATIONS]: 'arbitrations',
  [Route.ADMIN_AUCTIONS]: 'auctions',
  [Route.ADMIN_BROADCASTS]: 'broadcasts',
  [Route.ADMIN_COMPANIES]: 'companies',
  [Route.ADMIN_EVENTS]: 'events',
  [Route.ADMIN_EVENTS_LANE_MANAGEMENT]: 'lane_management',
  [Route.ADMIN_LIVE_LANES_AUCTIONEER]: 'auctioneer',
  [Route.ADMIN_LIVE_LANES_AUCTION_CLERK]: 'auction_clerk',
  [Route.ADMIN_LIVE_LANES_MARQUEE]: 'marquee',
  [Route.ADMIN_REPORTS]: 'reports',
  [Route.ADMIN_REPORT]: 'reports',
  [Route.ADMIN_USERS]: 'users',

  [Route.AUTH_CARFAX_USA]: 'login',
  [Route.AUTH_EMPLOYEE]: 'login',
  [Route.AUTH_FORGOT_PASSWORD]: 'forgot_password',
  [Route.AUTH_LOGIN]: 'login',
  [Route.AUTH_LOGOUT]: 'success',
  [Route.AUTH_PIPELINE]: 'login',
  [Route.AUTH_REGISTER]: 'register',
  [Route.AUTH_REQUEST_INVITE]: 'request_invite',
  [Route.AUTH_RESET_PASSWORD]: 'reset_password',

  [Route.BROWSE]: 'browse',

  [Route.BUY_BUY_NOW]: 'buy_now',
  [Route.BUY_COMPARABLES]: 'comparables',
  [Route.BUY_LIVE_AUCTION]: 'live_auction',
  [Route.BUY_RUN_LIST]: 'run_list',
  [Route.BUY_TIMED_AUCTION]: 'timed_auction',

  [Route.ERROR_404]: '404_page_not_found',

  [Route.FINISHED_CANCELLED]: 'auction_item_status_sale_cancelled',
  [Route.FINISHED_NO_SALE]: 'auction_item_status_no_sale',
  [Route.FINISHED_PURCHASED]: 'purchased',
  [Route.FINISHED_SOLD]: 'auction_item_status_sold',

  [Route.MY_BLOCK_BUYER]: 'my_block_buyer',
  [Route.MY_BLOCK_SELLER]: 'my_block_seller',

  [Route.MY_MARKET_GUIDE]: 'my_market_guide',

  [Route.NOTIFICATION_SETTINGS]: 'notification_settings',

  [Route.PENDING_AWAITING_CHECKOUT]: 'auction_item_status_awaiting_checkout',
  [Route.PENDING_DELIVERY]: 'auction_item_status_pending_delivery',
  [Route.PENDING_IN_IF_BID]: 'auction_item_status_in_if_bid',

  [Route.SELL_PARKED]: 'parked',
};
