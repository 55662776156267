// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#40618e","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","button":"button_da16ccc","active":"active_b8432b8","theme-base":"theme-base_a586ded","hover":"hover_a6e15f9","attached":"attached_bc51672","outline":"outline_fa5855d","theme-black":"theme-black_f8328a8","theme-black-attached":"theme-black-attached_a279b80","theme-blue":"theme-blue_a70620e","theme-blue-attached":"theme-blue-attached_d75ce65","theme-blue-outline":"theme-blue-outline_c0586f3","theme-green":"theme-green_e05a31f","theme-green-attached":"theme-green-attached_ff169e7","theme-green-outline":"theme-green-outline_c561f07","theme-red":"theme-red_fd54477","theme-red-attached":"theme-red-attached_dd2cd3a","theme-red-outline":"theme-red-outline_df8e4b7","theme-gray-attached":"theme-gray-attached_f0ad710","theme-gray-outline":"theme-gray-outline_b002895","theme-yellow":"theme-yellow_baef568","theme-yellow-attached":"theme-yellow-attached_cb11ed1","theme-yellow-outline":"theme-yellow-outline_bee8846","theme-white":"theme-white_a6dfe55"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#40618e\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"button\":\"button_da16ccc\",\"active\":\"active_b8432b8\",\"theme-base\":\"theme-base_a586ded\",\"hover\":\"hover_a6e15f9\",\"attached\":\"attached_bc51672\",\"outline\":\"outline_fa5855d\",\"theme-black\":\"theme-black_f8328a8\",\"theme-black-attached\":\"theme-black-attached_a279b80\",\"theme-blue\":\"theme-blue_a70620e\",\"theme-blue-attached\":\"theme-blue-attached_d75ce65\",\"theme-blue-outline\":\"theme-blue-outline_c0586f3\",\"theme-green\":\"theme-green_e05a31f\",\"theme-green-attached\":\"theme-green-attached_ff169e7\",\"theme-green-outline\":\"theme-green-outline_c561f07\",\"theme-red\":\"theme-red_fd54477\",\"theme-red-attached\":\"theme-red-attached_dd2cd3a\",\"theme-red-outline\":\"theme-red-outline_df8e4b7\",\"theme-gray-attached\":\"theme-gray-attached_f0ad710\",\"theme-gray-outline\":\"theme-gray-outline_b002895\",\"theme-yellow\":\"theme-yellow_baef568\",\"theme-yellow-attached\":\"theme-yellow-attached_cb11ed1\",\"theme-yellow-outline\":\"theme-yellow-outline_bee8846\",\"theme-white\":\"theme-white_a6dfe55\"}";
        // 1737753267972
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  