import { debounce } from 'lodash-es';

import BaseClass from 'components/ui/shared/base';
import Checkbox from 'forms/shared/checkbox';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InputText from 'forms/shared/inputText';
import Select from 'forms/shared/select';
import SelectCompany, { SelectCompanyOption } from 'forms/shared/selectCompany';
import SelectCompanyUser, { SelectCompanyUserOption } from 'forms/shared/selectCompanyUsers';
import {
  AuctionItem,
  AuctionItemStatus,
  MutationauctionItemTransferSaleArgs,
  QueryasIsCauseArgs,
  UserCompanyRelationshipStatus,
} from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { getTranslatedAuctionItemStatus } from 'utils/intlStringUtils';
import { isQualifiedForAsIs } from 'store/shared/api/graph/queries/asIs';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface DialogProps {
  isOpen: boolean;
  onSubmit: (shouldSubmit: boolean, options: MutationauctionItemTransferSaleArgs) => void;
  errorMessages?: ErrorMessages;
  progress: boolean;
  auctionItem: AuctionItem;
}

interface DialogState {
  companyId?: string;
  status?: AuctionItemStatus;
  userId?: string;
  amount?: number;
  asIs: boolean;
  isAsIsEnabled: boolean;
}

class Dialog extends BaseClass<DialogProps, DialogState> {
  private statusDropdownData: { label: string; value: AuctionItemStatus }[];

  constructor(props) {
    super(props);

    this.state = {
      amount: props?.auctionItem?.bidTimeline?.list?.[0]?.amount?.amount ?? undefined,
      asIs: !!props?.auctionItem?.asIs || false,
      companyId: undefined,
      isAsIsEnabled: !!props?.auctionItem?.asIs || false,
      status: undefined,
      userId: undefined,
    };

    this.statusDropdownData = [
      { value: AuctionItemStatus.IN_IF_BID, label: getTranslatedAuctionItemStatus(AuctionItemStatus.IN_IF_BID) },
      { value: AuctionItemStatus.SOLD, label: getTranslatedAuctionItemStatus(AuctionItemStatus.SOLD) },
    ];
  }

  onPriceChange = debounce(async (value) => {
    const { auctionItem } = this.props;
    const options: QueryasIsCauseArgs = {
      inventoryItemId: auctionItem?.inventoryItem?.id ?? '',
      auctionId: auctionItem?.auction?.id,
      auctionFormat: auctionItem?.format,
      price: value,
    };

    const isAsIsEnabled = !!(await isQualifiedForAsIs(options))?.data?.data?.asIsCause;
    this.setState({ isAsIsEnabled });
  }, 250);

  onSubmit = (shouldSubmit: boolean) => {
    const { companyId, status, userId, amount, asIs } = this.state;
    const {
      auctionItem: { id },
      onSubmit,
    } = this.props;

    const options: MutationauctionItemTransferSaleArgs = {
      auctionItemId: id,
      consignerId: companyId,
      status,
      userId,
      amount,
      asIs,
    };

    onSubmit(shouldSubmit, options);
  };

  render() {
    const { status, userId, asIs, amount, isAsIsEnabled } = this.state;
    const { isOpen, errorMessages, progress } = this.props;

    const hasError = (name) => errorMessages && errorMessages.find((err) => err.name === name);
    const actionable = !!(status && userId && asIs !== undefined && !!amount && !Number.isNaN(amount));

    return (
      <ConfirmDialog
        actionable={actionable}
        actionLabel={t('confirm')}
        actionProgress={progress}
        isOpen={isOpen}
        onConfirm={this.onSubmit}
        theme="blue"
        title={t('transfer_sale')}
      >
        <p className={style.instructions}>
          Please enter the consigner and user of the new buyer. The price entered will be the starting offer or sale
          price.
        </p>
        <fieldset className={style.inputGroup}>
          <Select
            className={style.select}
            onChange={(option) => this.setState({ status: option?.value })}
            options={this.statusDropdownData}
            placeholder={t('select_status')}
          />
          <SelectCompany
            onChange={(company: SelectCompanyOption) => this.setState({ companyId: company?.value, userId: null })}
            placeholder={t('select_company')}
          />
          <SelectCompanyUser
            companyId={this.state?.companyId}
            connectionVariables={{
              status: UserCompanyRelationshipStatus.ENABLED,
            }}
            isDisabled={!this.state.companyId}
            onChange={(user: SelectCompanyUserOption) => this.setState({ userId: user?.value })}
            placeholder={t('select_user')}
          />
          <InputText
            className={style.amount}
            defaultValue={amount}
            onChange={(value) => {
              const formattedAmount = parseFloat(value) || undefined;
              this.setState({ amount: formattedAmount });
              this.onPriceChange(formattedAmount);
            }}
            placeholder={t('amount')}
            textClassName={style.amount}
            theme={hasError('amount') ? 'error' : undefined}
            type="currency"
          />
          {isAsIsEnabled && (
            <Checkbox
              checked={asIs}
              dataTestId="sold-as-is-checkbox"
              id="transferSaleAsIs"
              name="transferSaleAsIs"
              onChange={(event) => this.setState({ asIs: event.target.checked })}
            >
              {t('vehicle_will_be_sold_as_is')}
            </Checkbox>
          )}
        </fieldset>
        {errorMessages &&
          errorMessages.map((errorMessage, index) => (
            <p key={`error${index}`} className={style.errorMessage}>
              {errorMessage.message}
            </p>
          ))}
      </ConfirmDialog>
    );
  }
}

export default Dialog;
