import classnames from 'classnames';

import closeGlyph from 'glyphs/close.svg';

import Button from 'components/ui/shared/button';
import MiniButton from 'components/ui/shared/buttons/miniButton';
import Sprite from 'components/ui/shared/sprite';

import style from './systemBanner.scss';

interface Props {
  /** The button text */
  buttonText: string;
  /** The styles to optionally override the defaults */
  className?: string;
  /** Function invoked when button is clicked */
  onButtonClick?: () => void;
  /** Function invoked when `x` (dismiss) button is clicked */
  onDismiss?: () => void;
  /** The banner text */
  text: string;
  /** The banner colour theme */
  theme?: 'blue' | 'red';
}

const SystemBanner = ({ buttonText, className, onButtonClick, onDismiss, text, theme = 'red' }: Props) => {
  return (
    <div className={classnames(style.container, style[`theme-${theme}`], className)}>
      <div>{text}</div>
      <div className={style.buttons}>
        <MiniButton className={style.button} onClick={onButtonClick} theme="red">
          {buttonText}
        </MiniButton>
        {onDismiss && (
          <Button className={style.dismissButton} onClick={onDismiss} theme="none">
            <Sprite glyph={closeGlyph} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default SystemBanner;
