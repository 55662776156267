import ApiRequest from 'store/shared/api/apiRequest';
import { QueryasIsCauseArgs } from 'store/shared/api/graph/interfaces/types';

/**
 * Determines if an inventoryItem can be listed AS IS.
 */
export const asIsCause = ({ inventoryItemId, auctionId, auctionFormat, price }: QueryasIsCauseArgs) =>
  ApiRequest<{ asIsCause: string }>({
    data: {
      operationName: 'asIsCause',
      query: `
        query($inventoryItemId: String!, $auctionId: String!, $auctionFormat: AuctionItemFormat!, $price: Float!) {
          asIsCause(inventoryItemId: $inventoryItemId, auctionId: $auctionId, auctionFormat: $auctionFormat, price: $price)
        }
      `,
      variables: {
        inventoryItemId,
        auctionId,
        auctionFormat,
        price,
      },
    },
  });

/**
 * A helper function for `asIsCause`.
 * Queries if the price exists and is a valid number; otherwise returns a 'false' mock response.
 */
export const isQualifiedForAsIs = (options: QueryasIsCauseArgs) => {
  if (options?.price && !Number.isNaN(options?.price)) {
    return asIsCause(options);
  }
  return Promise.resolve({ data: { data: { asIsCause: null } } });
};
