import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import bullhorn from 'glyphs/bullhorn-active.svg';
import send from 'glyphs/send-right.svg';

import Button from 'components/ui/shared/button';
import ChatError from 'components/ui/chat/chatError';
import ChatMessages from 'components/ui/chat/chatMessages';
import ChatSidePanel, { ChatSection } from 'components/ui/chat/chatSidePanel';
import MessageInput from 'components/ui/chat/messageInput';
import User from 'constants/user';
import { ChatErrorState, ConversationState, ErrorStatus } from 'store/chat/chatModels';
import { ConversationType } from 'store/shared/api/graph/interfaces/types';
import { SpinnerCentered } from 'components/ui/loading/loading';
import { formatUserName } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

import style from './chat.scss';

export interface Props {
  /** CSS style to override chat container's default style. */
  className?: string;
  /** If true, we are still in process of connecting to the chat server */
  connecting?: boolean;
  /** The conversation currently being viewed in the chat */
  conversation: ConversationState | null;
  /** Errors from either sending a message or starting a conversation */
  errorState?: ChatErrorState;
  /** Callback for retrieving a username from an id */
  getUserName?: (userId: string) => string | null;
  /** Determines if extra context info should be displayed with each message */
  isContextInfoDisplayed?: boolean;
  /** Whether the chat window is in read only mode or not - no sending capability only message display */
  isReadOnly?: boolean;
  /** Whether the side panel should be hidden or not. */
  isSidePanelHidden?: boolean;
  /**
   * If this callback is set and the isReadOnly is also set, then a join chat button will appear. When called
   *  it will invoke this callback.
   */
  onJoinConversation?: (() => void) | null;
  /** Callback when user enters a message.  Allows container to send the message to a provider */
  onMessageAdded?: (channelId: string | undefined, message: string) => void;
  /** Function called to refresh this chat component */
  onRefreshChat?: () => void;
  /** Descriptors of the different chat sections available in the chat window */
  sections?: ChatSection[];
  /** Callback for when a different conversation is selected.  Allows container to register the change */
  setCurrentConversationId?: (channelId: string) => void;
  /** Current user that is interacting with the chat window */
  user: User;
}

const Chat = ({
  className,
  connecting,
  conversation,
  errorState,
  getUserName,
  isContextInfoDisplayed = false,
  isReadOnly = false,
  isSidePanelHidden = false,
  onJoinConversation,
  onMessageAdded = () => {},
  onRefreshChat,
  sections = [],
  setCurrentConversationId = () => {},
  user,
}: Props) => {
  const [isProcessing, setProcessing] = useState<boolean>(false);

  /**
   * Method for retrieving the user profile from the conversation object
   */
  const getUserNameFromConversation = useCallback(
    (userId: string): string | null => {
      if (userId === conversation?.customer?.id) {
        return formatUserName(conversation.customer) || null;
      }
      if (userId === conversation?.staff?.id) {
        return formatUserName(conversation.staff) || null;
      }
      return null;
    },
    [conversation]
  );

  /**
   * Reset submitting state when messages update or errors occur
   */
  useEffect(() => setProcessing(false), [conversation, errorState]);

  return (
    <div
      className={classnames(style.container, isSidePanelHidden && style.sidePanelHidden, className)}
      data-testid="chat-panel"
    >
      {!isSidePanelHidden && (
        <ChatSidePanel
          chatSections={sections}
          onChannelClick={setCurrentConversationId}
          onRefreshChat={onRefreshChat}
          selectedChannelId={conversation?.id}
        />
      )}
      {connecting && <SpinnerCentered className={style.spinner} />}
      {!connecting && (
        <div className={style.chatMessagesContainer}>
          <ChatMessages
            conversation={conversation}
            getUserName={getUserName ?? getUserNameFromConversation}
            isContextInfoDisplayed={isContextInfoDisplayed}
            userId={user.id}
          />
          <ChatError errorState={errorState} />
          {isReadOnly && onJoinConversation && !isProcessing && (
            <Button
              className={style.joinButton}
              dataTestId="chat-join-chat-button"
              onClick={async () => {
                setProcessing(true);
                onJoinConversation();
              }}
              theme="blue"
            >
              {t('chat_join_chat')}
            </Button>
          )}
          {(!isReadOnly || isProcessing) && (
            <MessageInput
              dataTestId="chat-message-input"
              disabled={errorState?.status === ErrorStatus.FATAL_ERROR || !conversation || !conversation.id}
              isSubmitting={isProcessing}
              onSubmit={async (value: string) => {
                setProcessing(true);
                onMessageAdded(conversation?.id, value);
              }}
              placeholder={
                conversation?.type === ConversationType.LIVE_LANE_BROADCAST
                  ? t('send_broadcast_message')
                  : t('send_message_to_the_auction_clerk')
              }
              recipientName={!isSidePanelHidden ? formatUserName(conversation?.customer) : undefined}
              sendMessageIcon={conversation?.type === ConversationType.LIVE_LANE_BROADCAST ? bullhorn : send}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Chat;
