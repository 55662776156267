import classnames from 'classnames';
import { Link } from 'react-router';
import { useCallback } from 'react';

import {
  FacetLabelGroup,
  ButtonsFacetGroupConfigItem,
} from 'components/ui/lists/filters/facetGroups/interfaces/facetGroupTypes';
import Logger from 'logging/Logger';
import { Location } from 'constants/reactRouter';
import { facetLink } from './facetGroups';
import { t } from 'utils/intlUtils';

import style from './buttonsFacetGroup.scss';

interface Props {
  /** The configuration information associated to the button grouping. */
  configItem: ButtonsFacetGroupConfigItem;
  /** The facet group for the buttons. */
  facetGroup: FacetLabelGroup | undefined;
  /** The label of the button grouping. */
  label: string | undefined;
  /** The current url information for the button links. */
  location: Location;
}

const ButtonsFacetGroup = ({ facetGroup, label, configItem: { showAny = true }, location }: Props) => {
  /**
   * Handles the onClick event for the button links.
   */
  const handleClick = useCallback(
    (facetName: string) => {
      Logger.trackUserAction(`filter_${facetGroup?.name}_${facetName}_click`);
    },
    [facetGroup?.name]
  );

  if (!facetGroup) {
    return null;
  }

  return (
    <div className={style.buttonsFacetGroup} data-testid="buttonsFacetGroup">
      <div className={style.heading}>{label || facetGroup.name}</div>
      <div className={style.buttons}>
        {facetGroup.facets?.map(({ name, selected }) => (
          <Link
            key={name}
            className={classnames(style.button, { [style.selected]: selected })}
            onClick={() => handleClick(name)}
            to={facetLink(facetGroup.name, location, name)}
          >
            {name}
          </Link>
        ))}
        {showAny && (
          <Link
            className={classnames(style.button, {
              [style.selected]: facetGroup.facets?.reduce((selected, f) => selected && !f.selected, true),
            })}
            onClick={() => handleClick('any')}
            to={facetLink(facetGroup.name, location)}
          >
            {t('any')}
          </Link>
        )}
      </div>
    </div>
  );
};

export default ButtonsFacetGroup;
