import { combineReducers } from 'redux';

import { arbitrationDetailsReducer } from './arbitrations/details/arbitrationDetailsReducer';
import { arbitrationsReducer } from './arbitrations/list/arbitrationsReducer';
import { auctionClerkReducer } from 'store/admin/liveLanes/auctionClerk/auctionClerkReducer';
import { auctionDetailsReducer } from 'store/admin/auctions/details/auctionDetailsReducer';
import { auctioneerReducer } from 'store/admin/liveLanes/auctioneer/auctioneerReducer';
import { auctionsReducer } from 'store/admin/auctions/list/auctionsReducer';
import { broadcastDetailsReducer } from 'store/admin/broadcasts/details/broadcastDetailsReducer';
import { broadcastsReducer } from 'store/admin/broadcasts/list/broadcastsReducer';
import { companiesReducer } from './companies/list/companiesReducer';
import { companyDetailsReducer } from './companies/details/companyDetailsReducer';
import { eventDetailsReducer } from 'store/admin/events/details/eventDetailsReducer';
import { eventsReducer } from 'store/admin/events/list/eventsReducer';
import { laneManagerReducer } from 'store/admin/liveLanes/laneManager/laneManagerReducer';
import { marqueeReducer } from 'store/admin/liveLanes/marquee/marqueeReducer';
import { userDetailsReducer } from './users/details/userDetailsReducer';
import { usersReducer } from './users/list/usersReducer';

export const adminReducer = combineReducers({
  arbitrationDetails: arbitrationDetailsReducer,
  arbitrations: arbitrationsReducer,
  auctionClerk: auctionClerkReducer,
  auctionDetails: auctionDetailsReducer,
  auctioneer: auctioneerReducer,
  auctions: auctionsReducer,
  broadcastDetails: broadcastDetailsReducer,
  broadcasts: broadcastsReducer,
  companies: companiesReducer,
  companyDetails: companyDetailsReducer,
  eventDetails: eventDetailsReducer,
  events: eventsReducer,
  laneManager: laneManagerReducer,
  marquee: marqueeReducer,
  userDetails: userDetailsReducer,
  users: usersReducer,
});
