import { ReactNode } from 'react';
import classnames from 'classnames';

import closeGlyph from 'glyphs/close.svg';

import Button from 'components/ui/shared/button';
import Dialog from 'components/ui/shared/dialogs/dialog';
import Sprite from 'components/ui/shared/sprite';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import { Spinner } from 'components/ui/loading/loading';
import { t } from 'utils/intlUtils';

import style from './confirmDialog.scss';

export interface ConfirmDialogProps {
  /** The label of the confirm button. */
  actionLabel?: string;
  /** True when spinner is displayed. */
  actionProgress?: boolean;
  /** True when action button is enabled. */
  actionable?: boolean;
  /** CSS styling to overwrite the default body style. */
  bodyClassName?: string;
  /**
   * True when z-index CSS styling is applied.
   * Use when layering two modals on top of each other.
   */
  bringToFront?: boolean;
  /** The child elements to be rendered. */
  children?: ReactNode;
  /** CSS styling to overwrite the default style. */
  className?: string;
  /** CSS styling to overwrite the default container style. */
  containerClassName?: string;
  /** Test id for testing component. */
  contentDataTestId?: string;
  /** Testing ID to reference this confirm dialog */
  dataTestId?: string;
  /** Disables on close click handler. */
  disableRequestClose?: boolean;
  /** The label of the cancel button. */
  dismissLabel?: string;
  /** Error message override to show forced error(s) */
  errorsOverride?: ErrorMessages;
  /** CSS styling to overwrite the default footer style. */
  footerClassName?: string;
  /** CSS styling to overwrite the default header style. */
  headerClassName?: string;
  /** True when dialog is open. */
  isOpen: boolean;
  /** True when dialog prevents default on event. */
  isPreventingDefaultOnClick?: boolean;
  /** Function invoked when close button is clicked. */
  onClose?: (shouldSubmit?: boolean) => void;
  /** Function invoked when confirm button is clicked. */
  onConfirm?: (shouldSubmit: boolean, event?) => void;
  /** Function to handle clicking dismiss button; defaults to onClose */
  onDismiss?: () => void;
  /** The theme of the dialog. */
  theme?: 'blue' | 'green' | 'red' | 'yellow';
  /** The title of the dialog. */
  title: string;
  /** Icon rendered before the title. */
  titleIcon?: ReactNode;
  /** CSS styling to overwrite the default title style. */
  titleClassName?: string;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const {
    actionLabel,
    actionProgress,
    actionable = true,
    bodyClassName,
    bringToFront,
    children,
    className,
    containerClassName,
    contentDataTestId,
    disableRequestClose,
    dismissLabel,
    errorsOverride,
    footerClassName,
    headerClassName,
    isOpen,
    isPreventingDefaultOnClick,
    onClose,
    onConfirm,
    onDismiss,
    dataTestId,
    theme = 'blue',
    title,
    titleIcon,
    titleClassName,
  } = props;

  const onCancelClick = () => {
    if (onClose) {
      onClose();
    }

    if (onConfirm) {
      onConfirm(false);
    }
  };

  const onDismissClick = () => {
    if (onDismiss === undefined) {
      onCancelClick();
      return;
    }

    onDismiss();
  };

  const onSubmit = (e) => {
    e?.preventDefault();
    if (onConfirm) {
      onConfirm(true, e);
    }
  };

  const onRequestSubmit = () => {
    if (actionable) {
      onSubmit(undefined);
    }
  };

  return (
    <Dialog
      className={classnames(containerClassName, bringToFront && style.bringToFront)}
      contentDataTestId={contentDataTestId || 'confirm-dialog-content'}
      contentInnerClassName={classnames(style.confirmDialog, style[`theme-${theme}`], className)}
      dataTestId={dataTestId || 'confirm-dialog'}
      isOpen={isOpen}
      isPreventingDefaultOnClick={isPreventingDefaultOnClick}
      requestClose={!disableRequestClose ? onCancelClick : undefined}
      requestSubmit={onRequestSubmit}
    >
      <div className={classnames(style.header, headerClassName)} data-testid="confirm-dialog-header">
        {!!titleIcon && <div className={style.icon}>{titleIcon}</div>}
        <h5 className={classnames(style.title, titleClassName)}>{title}</h5>
        <button
          className={style.closeButton}
          onClick={!disableRequestClose ? onCancelClick : undefined}
          title={t('close')}
          type="button"
        >
          <Sprite className={style.sprite} glyph={closeGlyph} />
        </button>
      </div>
      <div className={classnames(style.body, bodyClassName)}>
        {errorsOverride && <FormErrors errorMessages={errorsOverride} isSmallDialog />}
        {children}
      </div>
      {onConfirm && (
        <div className={classnames(style.footer, footerClassName)}>
          {dismissLabel && (
            <Button className={classnames(style.button)} onClick={onDismissClick} theme="gray-attached">
              {dismissLabel || t('cancel')}
            </Button>
          )}
          <Button
            className={style.button}
            dataTestId="confirmButton"
            disabled={!actionable}
            onClick={onSubmit}
            theme={theme ? `${theme}-attached` : 'none'}
          >
            {actionProgress ? (
              <div className={style.spinnerContainer}>
                <Spinner theme={theme ? 'white' : 'gray'} />
              </div>
            ) : (
              actionLabel || t('confirm')
            )}
          </Button>
        </div>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
