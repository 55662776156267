import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import AccountVerificationBanner from 'containers/notifications/systemBanners/accountVerification/accountVerificationBanner';
import UserSessionExpiryBanner from 'containers/notifications/userSessionExpiry/userSessionExpiryBanner';
import { AppState } from 'store/configureStore';
import { NAVIGATION_CONTAINER_ID } from 'containers/navigation/navigationContainer';
import { isAuctionStaff } from 'utils/userUtils';
import { useLocation } from 'constants/reactRouter';

const SystemBanners = () => {
  const { pathname } = useLocation();
  const user = useSelector((state: AppState) => state.app.user);

  // Whether the `AccountVerificationBanner` should be displayed
  const isAccountVerifiedBannerActive = useMemo(
    () =>
      !user.employee &&
      user?.companyRelationships?.list?.length &&
      !isAuctionStaff(user) &&
      (!user.emailVerified || !user.phoneVerified),
    [user]
  );

  // Update system navigation height css variable
  useEffect(() => {
    const navHeight = document.getElementById(NAVIGATION_CONTAINER_ID)?.getBoundingClientRect()?.height ?? 0;
    document.documentElement.style.setProperty('--system-navigation-height-full', `${navHeight}px`);
  }, [user, isAccountVerifiedBannerActive, pathname]);

  return (
    <>
      {isAccountVerifiedBannerActive && <AccountVerificationBanner />}
      <UserSessionExpiryBanner />
    </>
  );
};

export default SystemBanners;
