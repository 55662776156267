/* eslint-disable camelcase */

import { PartialDeep } from 'type-fest';

import AuctionItem from 'constants/auctionItem';
import AuthService from 'store/shared/services/authService';
import InventoryItem from 'constants/inventoryItem';
import Logger from 'logging/Logger';
import { LooseObject } from 'constants/objects';
import { UserAction } from 'logging/analytics/events/userActions';
import { isAuctionStaff, representsCompany } from 'utils/userUtils';

/**
 * Get common attributes in relation to a user action on a AuctionItem.
 */
export const getAuctionItemUserActionAttributes = (auctionItem: PartialDeep<AuctionItem> | undefined) => {
  const user = AuthService.user;

  return {
    auction_item_id: auctionItem?.id,
    is_auction_staff: isAuctionStaff(user, auctionItem?.auction?.id),
    is_seller: representsCompany(user, auctionItem?.inventoryItem?.company?.id),
  };
};

/**
 * Get common attributes in relation to a user action on an Inventory Item.
 */
export const getInventoryItemUserActionAttributes = (inventoryItem: PartialDeep<InventoryItem> | undefined | null) => {
  const user = AuthService.user;

  return {
    inventory_item_id: inventoryItem?.id,
    is_auction_staff: isAuctionStaff(user),
    is_seller: representsCompany(user, inventoryItem?.company?.id),
  };
};

/**
 * Get common attributes in relation to a user action on current user
 */
export const getUserActionAttributes = () => {
  const user = AuthService.user;
  return {
    is_auction_staff: isAuctionStaff(user),
  };
};

/**
 * Tracks a user action and applies common user attributes
 */
export const trackUserActionWithUserAttributes = (action: UserAction, attributes?: LooseObject) => {
  const actionAttributes = { ...getUserActionAttributes(), ...attributes };
  Logger.trackUserAction(action, actionAttributes);
};

/**
 * Tracks a user action and applies our common AuctionItem attributes.
 */
export const trackUserActionWithAuctionItemAttributes = (
  action: UserAction,
  auctionItem: PartialDeep<AuctionItem> | undefined,
  attributes?: LooseObject
) => {
  const actionAttributes = { ...getAuctionItemUserActionAttributes(auctionItem), ...attributes };
  Logger.trackUserAction(action, actionAttributes);
};

/**
 * Tracks a user's bid action and applies the bid price and our common AuctionItem attributes.
 */
export const trackUserBidActionWithAuctionItemAttributes = (
  action: UserAction,
  auctionItem: PartialDeep<AuctionItem> | undefined,
  bidAmount: number
) => {
  const actionAttributes = { ...getAuctionItemUserActionAttributes(auctionItem), bid_price: bidAmount };
  Logger.trackUserAction(action, actionAttributes);
};

/**
 * Tracks a user action and applies our common InventoryItem attributes.
 */
export const trackUserActionWithInventoryItemAttributes = (
  action: UserAction,
  inventoryItem: PartialDeep<InventoryItem> | undefined | null,
  attributes?: LooseObject
) => {
  const actionAttributes = { ...getInventoryItemUserActionAttributes(inventoryItem), ...attributes };
  Logger.trackUserAction(action, actionAttributes);
};
