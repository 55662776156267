import { MouseEvent, MouseEventHandler, useCallback, useMemo, useState } from 'react';

import InventoryItem from 'constants/inventoryItem';
import ObdiiSlideOut from 'components/sections/inventoryItem/details/conditionReport/blueDriverScanData/obdiiSlideOut';
import SmogSlideOut from 'components/sections/inventoryItem/details/conditionReport/blueDriverScanData/smogSlideOut';
import {
  BlueDriverCode,
  BlueDriverScanRequestStatus,
  BlueDriverSmogScan,
  VehicleCaptureType,
} from 'store/shared/api/graph/interfaces/types';
import { OBDIIButton, SmogButton } from './blueDriverButtons';
import { getBlueDriverScanData } from 'store/shared/api/graph/queries/blueDriverScanData';
import { isVerified } from 'utils/auctionItemUtils';

import style from './blueDriverScanData.scss';

enum SlideOutType {
  OBDII,
  SMOG,
}

interface Props {
  /** The inventory item details. */
  inventoryItem: InventoryItem;
  /** Function invoked when OBDII button clicked. */
  onObdiiClick?: MouseEventHandler<HTMLButtonElement>;
  /** Function invoked when Smog button clicked. */
  onSmogClick?: MouseEventHandler<HTMLButtonElement>;
}

const BlueDriverScanData = ({ inventoryItem, onObdiiClick, onSmogClick }: Props) => {
  const { blueDriverScanData } = inventoryItem;
  const [blueDriverCodes, setBlueDriverCodes] = useState<BlueDriverCode[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openSlideOutType, setOpenSlideOutType] = useState<SlideOutType>();
  const [smogScan, setSmogScan] = useState<BlueDriverSmogScan>();

  /**
   * Fetch blue driver scan data.
   */
  const fetchScanData = useCallback(() => {
    setIsLoading(true);
    getBlueDriverScanData({ inventoryItemId: inventoryItem.id })
      ?.then((response) => {
        setBlueDriverCodes(response?.data?.data?.inventoryItem?.blueDriverScanData?.codes?.filter(Boolean) || []);
        setSmogScan(response?.data?.data?.inventoryItem?.blueDriverScanData?.smogScan || undefined);
      })
      ?.finally(() => setIsLoading(false));
  }, [inventoryItem.id]);

  /**
   * Open slideOut and fetch scan data.
   */
  const onOpenSlideOut = useCallback(
    (slideOutType: SlideOutType) => {
      if (openSlideOutType !== slideOutType) {
        setOpenSlideOutType(slideOutType);
        fetchScanData();
      }
    },
    [fetchScanData, openSlideOutType]
  );

  /**
   * Close slideOut only requested slideOut matches the currently open one.
   */
  const onCloseSlideOut = useCallback(
    (slideOutType: SlideOutType) => {
      if (slideOutType === openSlideOutType) {
        setOpenSlideOutType(undefined);
      }
    },
    [openSlideOutType]
  );

  /**
   * Open OBDII slideOut
   */
  const onObdiiButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onOpenSlideOut(SlideOutType.OBDII);
      onObdiiClick?.(event);
    },
    [onObdiiClick, onOpenSlideOut]
  );

  /**
   * Open Smog slideOut
   */
  const onSmogButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onOpenSlideOut(SlideOutType.SMOG);
      onSmogClick?.(event);
    },
    [onOpenSlideOut, onSmogClick]
  );

  /**
   * Display Smog button when USA verified and smog scan exists.
   */
  const displaySmogButton = useMemo(() => {
    if (!blueDriverScanData?.smogScanRequestStatus) {
      return false;
    }
    return (
      [
        VehicleCaptureType.VERIFIED_AUTOGRADE_CAPTURE,
        VehicleCaptureType.VERIFIED_EXTENSIVE_VEHICLE_CONDITION_CAPTURE,
        VehicleCaptureType.VERIFIED_EXTERNAL_AUTOGRADE_CAPTURE,
      ].includes(inventoryItem?.captureType) &&
      [
        BlueDriverScanRequestStatus.COMPLETE,
        BlueDriverScanRequestStatus.PENDING,
        BlueDriverScanRequestStatus.FAILED,
      ].includes(blueDriverScanData.smogScanRequestStatus)
    );
  }, [blueDriverScanData?.smogScanRequestStatus, inventoryItem?.captureType]);

  /**
   * Display OBDII button when USA or Canadian verified and scan has been completed.
   */
  const displayObdiiButton = useMemo(() => {
    if (!blueDriverScanData?.codeScanRequestStatus) {
      return false;
    }
    return (
      isVerified(inventoryItem?.captureType) &&
      [
        BlueDriverScanRequestStatus.COMPLETE,
        BlueDriverScanRequestStatus.PENDING,
        BlueDriverScanRequestStatus.FAILED,
      ].includes(blueDriverScanData.codeScanRequestStatus)
    );
  }, [blueDriverScanData?.codeScanRequestStatus, inventoryItem?.captureType]);

  if (!displaySmogButton && !displayObdiiButton) {
    // Prevent rendering if neither scans are available
    return null;
  }

  return (
    <>
      <div className={style.container}>
        {displaySmogButton && <SmogButton blueDriverScanData={blueDriverScanData!} onClick={onSmogButtonClick} />}
        {displaySmogButton && displayObdiiButton && <div className={style.divider} />}
        {displayObdiiButton && <OBDIIButton blueDriverScanData={blueDriverScanData!} onClick={onObdiiButtonClick} />}
      </div>
      <SmogSlideOut
        isLoading={isLoading}
        isOpen={openSlideOutType === SlideOutType.SMOG}
        onClose={() => onCloseSlideOut(SlideOutType.SMOG)}
        onRefresh={fetchScanData}
        smogScan={smogScan}
        smogScanRequestStatus={blueDriverScanData?.smogScanRequestStatus}
      />
      <ObdiiSlideOut
        blueDriverCodes={blueDriverCodes}
        codeScanRequestStatus={blueDriverScanData?.codeScanRequestStatus}
        isLoading={isLoading}
        isOpen={openSlideOutType === SlideOutType.OBDII}
        onClose={() => onCloseSlideOut(SlideOutType.OBDII)}
        onRefresh={fetchScanData}
      />
    </>
  );
};

export default BlueDriverScanData;
