import { MouseEventHandler, useMemo } from 'react';

import Button from 'components/ui/shared/button';
import {
  BlueDriverScanData,
  BlueDriverScanRequestStatus,
  BlueDriverSmogScanResult,
} from 'store/shared/api/graph/interfaces/types';
import { Chevron } from 'components/ui/shared/chevrons';
import { t } from 'utils/intlUtils';

import style from './blueDriverButtons.scss';

interface Props {
  /** The inventory item details. */
  blueDriverScanData: BlueDriverScanData;
  /** Function invoked when button clicked. */
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const SmogButton = ({ blueDriverScanData, onClick }: Props) => {
  const smogResult = blueDriverScanData?.smogScan?.result ?? BlueDriverSmogScanResult.Fail;
  const smogScanStatus = blueDriverScanData?.smogScanRequestStatus;

  const smogResultColour = useMemo(() => {
    switch (smogResult) {
      case BlueDriverSmogScanResult.Fail:
        return style.red;
      case BlueDriverSmogScanResult.Pass:
        return style.green;
      case BlueDriverSmogScanResult.Warning:
        return style.yellow;
      default:
        return undefined;
    }
  }, [smogResult]);

  const buttonLabel = useMemo(() => {
    if (smogScanStatus === BlueDriverScanRequestStatus.PENDING) {
      return <div>{t('pending')}</div>;
    }
    return <div className={smogResultColour}>{smogResult}</div>;
  }, [smogScanStatus, smogResult, smogResultColour]);

  return (
    <Button className={style.button} dataTestId="smog-button" onClick={onClick} theme="none">
      <div className={style.crRowTitle}>{t('smog_readiness')}</div>
      <div className={style.crRowContent}>
        {buttonLabel}
        <Chevron />
      </div>
    </Button>
  );
};

export const OBDIIButton = ({ blueDriverScanData, onClick }: Props) => {
  const codeCount = blueDriverScanData?.codeCount ?? 0;
  const codeScanStatus = blueDriverScanData?.codeScanRequestStatus;

  const buttonLabel = useMemo(() => {
    if (codeScanStatus === BlueDriverScanRequestStatus.PENDING) {
      return t('pending');
    }
    return codeCount === 0 ? t('no_codes_found') : t('codes_found');
  }, [codeCount, codeScanStatus]);

  return (
    <Button className={style.button} dataTestId="obdii-button" onClick={onClick} theme="none">
      <div className={style.crRowTitle}>{t('obdii')}</div>
      <div className={style.crRowContent}>
        {buttonLabel}
        <Chevron />
      </div>
    </Button>
  );
};
