// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#40618e","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","type-body-1":"type-body-1_fe9d20d","type-body-2":"type-body-2_b184243","type-body-3":"type-body-3_cc4c8bc","type-body-4":"type-body-4_e1ff52c","type-section-title":"type-section-title_f73839b","type-notes":"type-notes_c886e7d","type-item-title":"type-item-title_b65140f","type-item-subtitle":"type-item-subtitle_fb7d4cc","type-subtitle":"type-subtitle_a150bf0","type-title":"type-title_f66a179","editFacetGroup":"editFacetGroup_a93dd48","facet":"facet_a6275db","name":"name_d6f9b07","title":"title_eff8c2a","facets":"facets_f72ce80","count":"count_be5c3fc","selected":"selected_cb1d889","done":"done_ba18a31"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#40618e\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"type-body-1\":\"type-body-1_fe9d20d\",\"type-body-2\":\"type-body-2_b184243\",\"type-body-3\":\"type-body-3_cc4c8bc\",\"type-body-4\":\"type-body-4_e1ff52c\",\"type-section-title\":\"type-section-title_f73839b\",\"type-notes\":\"type-notes_c886e7d\",\"type-item-title\":\"type-item-title_b65140f\",\"type-item-subtitle\":\"type-item-subtitle_fb7d4cc\",\"type-subtitle\":\"type-subtitle_a150bf0\",\"type-title\":\"type-title_f66a179\",\"editFacetGroup\":\"editFacetGroup_a93dd48\",\"facet\":\"facet_a6275db\",\"name\":\"name_d6f9b07\",\"title\":\"title_eff8c2a\",\"facets\":\"facets_f72ce80\",\"count\":\"count_be5c3fc\",\"selected\":\"selected_cb1d889\",\"done\":\"done_ba18a31\"}";
        // 1737753277158
        var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  