import { ComponentClass, FunctionComponent, ReactNode } from 'react';
import { Location as HistoryLocation, Query as HistoryQuery } from 'history';
import {
  Router as ReactRouter,
  Route as ReactRouterRoute,
  RouteProps as ReactRouteProps,
  RouterProps as ReactRouterProps,
  RouteComponentProps,
  withRouter as withReactRouter,
} from 'react-router';
import { useSelector } from 'react-redux';

import { AppState } from 'store/configureStore';
import {
  AuctionItemFormat,
  AuctionItemStatus,
  AuctionTimeSlotStatus,
  TransactionType,
} from 'store/shared/api/graph/interfaces/types';

type WithNullUndefined<T> = {
  [P in keyof T]: T[P] | null | undefined;
};

export type DefaultQuery<T = string> = {
  [key: string]: T | T[];
};

interface LocationInterface<TQuery = DefaultQuery> extends HistoryLocation<WithNullUndefined<TQuery>> {
  query: HistoryQuery<WithNullUndefined<TQuery>>;
}

interface RouteInterface extends ReactRouteProps {
  /** Children Routes */
  children?: ReactNode;

  /** Auction Item Format */
  formats?: AuctionItemFormat[];

  /** True when the navigation shouldn't be displayed */
  isNavigationDisabled?: boolean;

  /** True sort by ascending order */
  sortAscending?: boolean;

  /** Sort Id */
  sortId?: string;

  /** Auction Item Status */
  status?: AuctionItemStatus[];

  /** Auction Time Slot Status */
  timeSlotStatus?: AuctionTimeSlotStatus;

  /** Auction Transaction Type */
  transactionType?: TransactionType;
}

interface RouterInterface extends ReactRouterProps {
  /** Children Routes */
  children?: ReactNode;
}

/**
 * When Component is wrapped with `withRouter()` HOC, this will override and omit router props
 * from the component actual component props.
 */
type WithRouter = <TProps = any>(
  component: ComponentClass<TProps> | FunctionComponent<TProps>,
  options?: Parameters<typeof withReactRouter>[1]
) => ComponentClass<Omit<TProps, keyof RouterProps>>;

export const Route: ComponentClass<RouteInterface> = ReactRouterRoute;

export const Router: ComponentClass<RouterInterface> = ReactRouter;

export const withRouter: WithRouter = withReactRouter;

export type Location<TQuery = DefaultQuery> = LocationInterface<TQuery>;

/**
 * Injected props for components wrapped with withRouter.
 * */
export interface RouterProps<TQuery = DefaultQuery>
  extends Omit<RouteComponentProps<Record<string, any>, Record<string, any>>, 'location'> {
  /**
   * Override location to be able to type and define `location.query`
   * */
  location: LocationInterface<TQuery>;

  /** Override routes to return our custom RouteInterface[] */
  routes: RouteInterface[];
}

/**
 * Alternative to the `withRouter` HOC, this would return the same `location` object
 * that is returned by `withRouter` HOC.
 *
 * Matches react-router's `useLocation` spec
 * @see {@link https://reactrouter.com/en/main/hooks/use-location}
 *
 * @returns {LocationInterface}
 */
export const useLocation = <TQuery extends Record<string, any>>(): LocationInterface<TQuery> => {
  const location = useSelector(
    (state: AppState) => state.routing.locationBeforeTransitions as LocationInterface<TQuery>
  );

  return location;
};
