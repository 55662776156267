import ApiRequest from 'store/shared/api/apiRequest';
import { QuerytransportFeeQuoteArgs, TransportFeeQuote } from 'store/shared/api/graph/interfaces/types';

export const transportFeeQuote = ({ auctionItemId, dropOffLocationId }: QuerytransportFeeQuoteArgs) =>
  ApiRequest<{ transportFeeQuote: TransportFeeQuote }>({
    data: {
      operationName: 'transportFeeQuote',
      query: `
      query ($auctionItemId: String!, $dropOffLocationId: String!) {
        transportFeeQuote (
          auctionItemId: $auctionItemId,
          dropOffLocationId: $dropOffLocationId,
        ) {
          amount {
            formattedAmount
          }
          disclaimers
          dropOffLocation {
            address1
            address2
            city
            country
            countryCode
            id
            latitude
            longitude
            name
            region
            regionCode
            zipCode
          }
        }
      }
    `,
      variables: {
        auctionItemId,
        dropOffLocationId,
      },
    },
  });
