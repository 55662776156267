import ApiRequest from 'store/shared/api/apiRequest';
import InventoryItem from 'constants/inventoryItem';
import { QueryinventoryItemArgs } from 'store/shared/api/graph/interfaces/types';

export const getBlueDriverScanData = ({ inventoryItemId }: QueryinventoryItemArgs) =>
  ApiRequest<{ inventoryItem: InventoryItem }>({
    data: {
      operationName: 'getBlueDriverScanData',
      query: `
        query($inventoryItemId: String!) {
          inventoryItem(inventoryItemId: $inventoryItemId) {
            ... on Vehicle {
              blueDriverScanData {
                codes {
                  code
                  description
                  system
                  type
                }
                codeCount
                smogScan {
                  checkEngineLights
                  completeMonitors
                  confirmedCodesNumber
                  incompleteMonitors
                  pendingCodesNumber
                  result
                }
              }
            }
          }
        }
    `,
      variables: {
        inventoryItemId,
      },
    },
  });
