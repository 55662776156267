import { connect, ConnectedProps } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import AuctionItemType from 'constants/auctionItem';
import Chat from 'components/ui/chat/chat';
import SimulcastChatManager from 'io/twilio/simulcastChatManager';
import { AppDispatch, AppState } from 'store/configureStore';
import {
  ChatErrorState,
  ConversationState,
  ErrorStatus,
  getDefaultFatalErrorState,
  getDefaultRecoverableErrorState,
} from 'store/chat/chatModels';
import { ConnectionState } from 'io/twilio/simulcastChatManagerTypes';
import { ConversationType } from 'store/shared/api/graph/interfaces/types';
import {
  InitialMessageLoadPolicy,
  useConversationsListener,
} from 'components/sections/auctionChat/hooks/useConversationsListener';
import { getErrors } from 'utils/apiUtils';
import { processClearConversations, processRegisterConversation, setErrorState } from 'store/chat/chatActions';
import { getUserData } from 'store/user/userActions';
import { useAggregatedErrorState } from 'components/sections/auctionChat/hooks/useAggregatedErrorState';
import { useChatManager } from 'components/sections/auctionChat/hooks/useChatManager';
import { useSendMessage } from 'components/sections/auctionChat/hooks/useSendMessage';

const stateConnect = (state: AppState) => ({
  /** Array of conversations that is visible to a Clerk */
  conversations: state?.app?.chat?.conversations,
  /** Current conversation id */
  currentConversationId: state?.app?.chat?.currentConversationId,
  /** Current conversation messages loaded into the chat window */
  currentConversationMessages: state?.app?.chat?.currentConversationMessages,
  /** Errors from either sending a message or starting a conversation */
  errorState: state?.app?.chat?.errorState,
  /** Current logged in user. */
  user: state?.app?.user,
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Clear all conversations (switching lanes) */
  clearAllConversations: () => processClearConversations({}, dispatch),
  /** Join the conversation with the Clerk */
  joinConversation: (options: { auctionTimeSlotLaneId: string }) =>
    processRegisterConversation({ ...options, forceCreate: true }, dispatch),
  /** Register the current conversation */
  registerConversation: (options: { auctionTimeSlotLaneId: string }) => processRegisterConversation(options, dispatch),
  /** Set chat window error state */
  setChatErrorState: (options: ChatErrorState) => dispatch(setErrorState(options)),
  fetchUserData: () => getUserData(dispatch, true),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The auction item current being auctioned */
  auctionItem: AuctionItemType;
  /** The id of the current auctionTimeSlotLane */
  auctionTimeSlotLaneId: string | undefined;
  /** The numeric value of the current bid amount from the live item */
  currentBidAmount: number | undefined;
}

const AuctionBuyerSellerChat = ({
  auctionItem,
  auctionTimeSlotLaneId,
  clearAllConversations,
  fetchUserData,
  conversations = {},
  currentBidAmount,
  currentConversationId,
  currentConversationMessages = [],
  errorState,
  joinConversation,
  registerConversation,
  setChatErrorState,
  user,
}: Props) => {
  const [isInitialConversationsLoaded, setIsInitialConversationsLoaded] = useState<boolean>(false);
  const [connectionState, setConnectionState] = useState<ConnectionState>(ConnectionState.DISCONNECTED);
  const chatManager: SimulcastChatManager = useChatManager({ setConnectionState });

  useEffect(() => {
    // Refetch user data to get updated session Id
    fetchUserData();
  }, [fetchUserData]);

  /**
   * If the auctionTimeSlotLaneId changed, then we want to clear all conversations and re-initialize the
   * default conversation.
   */
  useEffect(() => {
    chatManager?.removeAllListeners();
    setIsInitialConversationsLoaded(false);
    clearAllConversations();
  }, [auctionTimeSlotLaneId, chatManager, clearAllConversations]);

  useConversationsListener({
    chatManager,
    connectionState,
    conversations,
    currentConversationId,
    messageLoadingPolicy: InitialMessageLoadPolicy.CURRENT_AND_BROADCAST,
    setError: () => {
      setChatErrorState(getDefaultFatalErrorState());
    },
  });
  const onSendMessage: (conversation: ConversationState, message) => void = useSendMessage({
    auctionItemId: auctionItem?.id,
    chatManager,
    conversationId: currentConversationId,
    currentBidAmount,
    user,
    setError: () => {
      setChatErrorState(getDefaultRecoverableErrorState());
    },
  });

  /**
   * Register the conversation when the chat window is opened if we're a buyer or seller
   * If the chat is opened by a clerk, they need to click on one of the participants in the side
   * window before the conversation is seen.
   */
  useEffect(() => {
    if (
      connectionState === ConnectionState.CONNECTED &&
      auctionTimeSlotLaneId &&
      !isInitialConversationsLoaded &&
      auctionItem
    ) {
      registerConversation({ auctionTimeSlotLaneId })
        .then(() => setIsInitialConversationsLoaded(true))
        .catch((err) => setChatErrorState({ status: ErrorStatus.FATAL_ERROR, errors: getErrors(err) }));
    }
  }, [
    auctionItem,
    auctionTimeSlotLaneId,
    connectionState,
    isInitialConversationsLoaded,
    registerConversation,
    setChatErrorState,
  ]);

  const isReadOnlyBroadcast: boolean = useMemo(() => {
    return conversations[currentConversationId]?.type === ConversationType.LIVE_LANE_BROADCAST;
  }, [conversations, currentConversationId]);

  const aggregatedErrorState: ChatErrorState = useAggregatedErrorState({ connectionState, errorState });

  return (
    <Chat
      connecting={connectionState === ConnectionState.DISCONNECTED}
      conversation={{ ...conversations[currentConversationId], messages: currentConversationMessages }}
      errorState={aggregatedErrorState}
      isReadOnly={isReadOnlyBroadcast}
      isSidePanelHidden
      onJoinConversation={
        isReadOnlyBroadcast && auctionTimeSlotLaneId
          ? () => {
              joinConversation({ auctionTimeSlotLaneId }).catch((err) =>
                setChatErrorState({ status: ErrorStatus.FATAL_ERROR, errors: getErrors(err) })
              );
            }
          : null
      }
      onMessageAdded={(conversationId: string, message: string) =>
        onSendMessage(conversations[conversationId], message)
      }
      user={user}
    />
  );
};

export default connector(AuctionBuyerSellerChat);
