import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';

interface AuthModel {
  /** Whether is auction access mode or not. */
  isAuctionAccessMode: boolean;

  /** Whether is logged in or not. */
  isLoggedIn: boolean;

  /** True if user is required to login via MFA */
  isVerificationCodeRequired: boolean;
  /**
   * An auth token is returned during the initial MFA rollout grace-period,
   * allowing the user to skip the MFA process and continue as they did in the past.
   */
  tempAuthToken?: string;
}

export type AuthProps = ServerRecordOf<AuthModel>;

/**
 * Initial state of model
 */
export const InitialState = ServerRecord<AuthModel>({
  isAuctionAccessMode: false,
  isLoggedIn: false,
  isVerificationCodeRequired: false,
  tempAuthToken: undefined,
});
