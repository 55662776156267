// TODO: Add typing
// @ts-nocheck

import classnames from 'classnames';
import { ReactNode } from 'react';

import BaseClass from 'components/ui/shared/base';
import DatePicker from 'forms/shared/datePicker';
import DateRangePicker from 'forms/shared/dateRangePicker';
import Label from './label';
import InputText from './inputText';
import TextArea from './textArea';
import Checkbox from './checkbox';
import RadioButtons from './radioButtons';
import Select from './select';
import { convertToSnakeCase } from 'utils/stringUtils';
import { SelectOption } from 'utils/interfaces/SelectOption';

import style from './inputGroup.scss';

export type InputGroupProps<T = string> = {
  // TODO: Support stricter typing
  [key: string]: any;
  children?: ReactNode;
  name: string;
  className?: string;
  outerClassName?: string;
  groupType?:
    | 'checkbox'
    | 'multiCheckbox'
    | 'radioGroup'
    | 'textarea'
    | 'dropdownCustom'
    | 'calendar'
    | 'calendar-range'
    | 'default'
    | 'select';
  label?: string;
  /** CSS styling to overwrite label style */
  labelClassName?: string;
  /** The label's tooltip description string */
  labelTooltip?: string;
  options?: SelectOption<T>[];
  selectedOptions?: SelectOption<T>[];
  hasMargin?: boolean;
  type?: string;
  dataTestId?: string;
};

/**
 * InputGroup renders a set of common inputs that are used in forms. Any properties not listed below are all inherited by the inputs.
 * Refer to `checkbox.js`, `textArea.js`, and `inputText.js` for additional supported properties
 */
class InputGroup<T = string> extends BaseClass<InputGroupProps<T>> {
  static defaultProps = {
    children: undefined,
    groupType: 'default',
    hasMargin: true,
    className: null,
    outerClassName: null,
    label: null,
    labelTooltip: null,
    options: [],
    selectedOptions: [],
  };

  renderFields() {
    const { groupType, labelClassName, options, selectedOptions, className, ...props } = this.props;

    switch (groupType) {
      case 'checkbox':
        return <Checkbox className={classnames(style.checkbox, className)} id={props.name} {...props} />;

      case 'multiCheckbox':
        return options.map((option) => {
          const checked = !!selectedOptions?.find((selectedOption) => selectedOption.value === option.value);
          return (
            <Checkbox
              key={option.value}
              checked={checked}
              className={className}
              id={option.value}
              isButtonTheme
              text={option.label}
              {...props}
              dataTestId={`${props.dataTestId}-${option.value}`}
            />
          );
        });

      case 'radioGroup': {
        const { label, name } = props;
        return (
          <div className={classnames(style.radioGroup, className)}>
            {label && <Label name={name}>{label}</Label>}
            <RadioButtons className={className} textClassName={style.radioGroupText} {...props} />
          </div>
        );
      }

      case 'textarea': {
        const { label, name, dataTestId } = props;

        delete props.hasMargin;
        delete props.labelTooltip;
        delete props.outerClassName;

        return (
          <div data-testid={dataTestId}>
            {label && <Label name={name}>{label}</Label>}
            <TextArea className={classnames(style.textarea, className)} name={name} theme="small" {...props} />
          </div>
        );
      }

      case 'select': {
        const { label, name, dataTestId } = props;
        const testId = `select-${convertToSnakeCase(label)}`;

        // Find selected option if props value is not undefined
        const selectedOption =
          props?.value && 'id' in props.value && !props.isMulti
            ? (options.find(({ value }) => value === props.value?.id) ?? null)
            : props?.value;

        return (
          <div data-testid={dataTestId || testId}>
            {label && (
              <Label className={labelClassName} name={name}>
                {label}
              </Label>
            )}
            <Select
              className={classnames(style.inputGroupSelect, className)}
              id={testId}
              inputId={name}
              options={options}
              {...props}
              // - `value` is spread after props to keep the props api simple, i.e.
              // an object value can be passed instead of a callback function.
              value={selectedOption}
            />
          </div>
        );
      }

      case 'calendar': {
        const { label, name, dataTestId, ...calendarProps } = props;

        delete calendarProps.children;
        delete calendarProps.defaultValue;
        delete calendarProps.hasMargin;
        delete calendarProps.labelTooltip;
        delete calendarProps.outerClassName;
        delete calendarProps.reference;

        return (
          <div className={style.calendarGroup} data-testid={dataTestId}>
            {label && <Label name={name}>{label}</Label>}
            <DatePicker
              key="singleDatePicker"
              className="datePickerContainer"
              focused
              inputIconClassName={style.calendarIcon}
              {...calendarProps}
            />
          </div>
        );
      }

      case 'calendar-range': {
        const { dataTestId, label, name, ...calendarProps } = props;

        delete calendarProps.children;
        delete calendarProps.defaultValue;
        delete calendarProps.hasMargin;
        delete calendarProps.labelTooltip;
        delete calendarProps.outerClassName;
        delete calendarProps.reference;

        return (
          <div data-testid={dataTestId}>
            {label && <Label name={name}>{label}</Label>}
            <DateRangePicker
              key="datePickerRange"
              className="dateRangePickerContainer"
              inputIconClassName={style.calendarIcon}
              {...calendarProps}
            />
          </div>
        );
      }

      default: {
        const type = props.type || 'text';
        const theme = type === 'currency' ? 'minimalSmallCurrency' : props.theme;

        return (
          <InputText
            baseClass={style.dropdownBase}
            className={classnames(style.inputText, className)}
            menuClassName={style.dropdownMenu}
            placeholderClass={style.dropdownPlaceholder}
            {...props}
            theme={theme}
            type={type}
          />
        );
      }
    }
  }

  renderChildren() {
    const { children, dataTestId, name, label } = this.props;

    return (
      <div data-testid={dataTestId}>
        {label && <Label name={name}>{label}</Label>}
        {children}
      </div>
    );
  }

  render() {
    const { children, outerClassName, groupType, hasMargin } = this.props;
    const className = classnames(style.inputGroup, style[groupType], outerClassName, hasMargin && style.hasMargin);

    return <div className={className}>{children ? this.renderChildren() : this.renderFields()}</div>;
  }
}

export default InputGroup;
