import { ComponentType, createRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { uniq } from 'lodash-es';

import AuctionItemDetailsDigital from 'components/sections/liveLanes/auctionItemDetailsDigital';
import AuctionItemDetailsPhysical from 'components/sections/liveLanes/auctionItemDetailsPhysical';
import AuthService from 'store/shared/services/authService';
import BaseClass from 'components/ui/shared/base';
import CompanyLocation from 'components/sections/inventoryItem/details/slideOut/companyLocation';
import Empty from 'components/sections/auctionItem/details/empty';
import InventoryItemDetailsAuditLog from 'components/sections/inventoryItem/details/slideOut/auditLog';
import InventoryItemDetailsAutobids from 'components/sections/inventoryItem/details/slideOut/autobids';
import InventoryItemDetailsBids from 'components/sections/inventoryItem/details/slideOut/bids';
import InventoryItemDetailsIfBid from 'components/sections/inventoryItem/details/slideOut/ifBids';
import InventoryItemDetailsPaint from 'components/sections/inventoryItem/details/slideOut/paint';
import InventoryItemDetailsPhotos from 'components/sections/inventoryItem/details/slideOut/photos';
import InventoryItemDetailsScore from 'components/sections/inventoryItem/details/slideOut/score';
import InventoryItemDetailsSlideOut, {
  SlideOutComponentProps,
} from 'components/sections/inventoryItem/details/slideOut/slideOut';
import InventoryItemDetailsTires from 'components/sections/inventoryItem/details/slideOut/tires';
import InventoryItemDetailsVehicleHistory from 'components/sections/inventoryItem/details/slideOut/vehicleHistory';
import Logger from 'logging/Logger';
import TransportEstimate from 'components/sections/inventoryItem/details/slideOut/transportEstimate';
import { AppDispatch, AppState } from 'store/configureStore';
import { LiveLane } from 'store/shared/api/graph/interfaces/types';
import { Location } from 'constants/reactRouter';
import { LooseObject } from 'constants/objects';
import { Route } from 'store/routing/routes';
import { UserAction } from 'logging/analytics/events/userActions';
import {
  clearAuctionItemDetails,
  processGetAuctionItemDetails,
} from 'store/auctionItemDetails/auctionItemDetailsActions';
import { isManuallyControlled } from 'utils/auctionItemUtils';
import { removeAuctionItem } from 'store/auctionItemsList/auctionItemsActions';
import { t } from 'utils/intlUtils';

import style from './auctionItemDetailsContainer.scss';

const stateConnect = (state: AppState) => ({
  /** Auction item details information. */
  auctionItemDetails: state.app.auctionItemDetails,
  /** System time offset. */
  timeOffset: state.app.system.timeOffset,
  /** Current logged in user. */
  user: state.app.user,
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to clear auction item. */
  clearAuctionItem: () => dispatch(clearAuctionItemDetails()),
  /** Callback function to get the auction item by id. */
  getAuctionItem: (options: { auctionItemId: string | undefined }) => processGetAuctionItemDetails(options, dispatch),
  /** Callback function to remove the auction item by id. */
  removeAuctionItemById: (id: string) => dispatch(removeAuctionItem(id)),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The auction item's id. */
  auctionItemId?: string;

  /** The live lane for the auction item. */
  liveLane?: LiveLane;

  /** The current url information for the component. */
  location: Location<any>;

  /** Callback function to refresh the list. */
  refreshList?: () => void;
}

interface State {
  /** The inventory details component to be rendered. */
  inventoryDetailsComponent?: ComponentType<SlideOutComponentProps>;

  /** The inventory details data to be rendered. */
  inventoryDetailsData?: LooseObject;

  /** The inventory details title to be rendered. */
  inventoryDetailsTitle?: string;

  /** Whether to show auction is ended or not. */
  showEnded: boolean;
}

class AuctionItemDetailsContainer extends BaseClass<Props, State> {
  private vehicleDetailsRef = createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      inventoryDetailsComponent: undefined,
      inventoryDetailsData: undefined,
      inventoryDetailsTitle: undefined,
      showEnded: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    const { auctionItemId, getAuctionItem } = this.props;

    if (auctionItemId) {
      getAuctionItem({ auctionItemId })?.then(() => this.trackUserAction(UserAction.VIEW_VDP));
    }
  }

  componentDidUpdate(prevProps) {
    const { auctionItemId, getAuctionItem } = prevProps;
    const { auctionItemId: auctionItemIdNext, auctionItemDetails: auctionItemDetailsNext } = this.props;

    if (auctionItemId !== auctionItemIdNext) {
      this.setState({ showEnded: false });
      this.closeInventoryDetails();

      if (auctionItemIdNext) {
        getAuctionItem({ auctionItemId: auctionItemIdNext })?.then(() => this.trackUserAction(UserAction.VIEW_VDP));
      }
    } else if (auctionItemDetailsNext?.details) {
      const ended = prevProps?.auctionItemDetails?.details?._ended;
      const endedNext = auctionItemDetailsNext.details?._ended;

      if (!ended && endedNext) {
        this.setState({ showEnded: true });
      }
    }

    if (
      prevProps?.auctionItemDetails?.details &&
      auctionItemDetailsNext?.details &&
      auctionItemDetailsNext?.details.id !== prevProps?.auctionItemDetails?.details?.id
    ) {
      if (this.vehicleDetailsRef?.current) {
        this.vehicleDetailsRef.current.scrollTop = 0;
      }
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    this.props.clearAuctionItem();
  }

  clearAuctionItem = () => {
    const {
      auctionItemDetails: { details },
      removeAuctionItemById,
      clearAuctionItem,
    } = this.props;

    if (details) {
      removeAuctionItemById(details.id);
    }
    clearAuctionItem();
  };

  handleFeatureClicked = (featureType, inventoryDetailsData) => {
    const {
      auctionItemDetails: { details },
    } = this.props;

    if (this.state?.inventoryDetailsComponent) {
      // Prevent opening another slide-out while one is already active
      return;
    }

    switch (featureType) {
      case 'AUTO_BIDS':
        this.openInventoryDetails(t('autobids'), InventoryItemDetailsAutobids, {
          autoBids: details.autoBids,
          user: this.props.user,
        });
        break;

      case 'AUDIT_LOG':
        this.openInventoryDetails(t('audit_log'), InventoryItemDetailsAuditLog, {
          inventoryItemId: details.inventoryItem.id,
        });
        break;

      case 'BIDS':
        this.openInventoryDetails(t('bids'), InventoryItemDetailsBids, {
          bidTimeline: details.bidTimeline,
          status: details.status,
        });
        break;

      case 'DAMAGE':
        this.openInventoryDetails(t('damage_photos'), InventoryItemDetailsPhotos, inventoryDetailsData);
        break;

      case 'IF_BIDS':
        this.openInventoryDetails(t('if_bids'), InventoryItemDetailsIfBid, {
          ifBidTimeline: details.ifBidTimeline,
          status: details.status,
          user: this.props.user,
        });
        break;

      case 'BUYER': {
        const {
          details: { buyer },
        } = this.props.auctionItemDetails;

        this.openInventoryDetails(t('buyer_details'), CompanyLocation, {
          company: buyer?.company,
          location: buyer?.company?.primaryLocation,
        });
        break;
      }

      case 'PAINT':
        this.openInventoryDetails(t('paint'), InventoryItemDetailsPaint, inventoryDetailsData);
        break;

      case 'PHOTOS':
        this.openInventoryDetails(t('photos'), InventoryItemDetailsPhotos, inventoryDetailsData);
        break;

      case 'SCORE':
        this.openInventoryDetails(t('vehicle_score'), InventoryItemDetailsScore, inventoryDetailsData);
        break;

      case 'SELLER': {
        const { inventoryItem } = details;

        this.openInventoryDetails(t('seller_details'), CompanyLocation, {
          company: inventoryItem.company,
          location: inventoryItem.location,
        });
        break;
      }

      case 'TIRES':
        this.openInventoryDetails(t('tires'), InventoryItemDetailsTires, inventoryDetailsData);
        break;

      case 'TRANSPORT_ESTIMATE': {
        this.openInventoryDetails(t('transport_estimate'), TransportEstimate, {
          ...inventoryDetailsData,
          onClose: this.closeInventoryDetails,
        });
        break;
      }

      case 'VEHICLE_HISTORY':
        this.openInventoryDetails(t('vehicle_history'), InventoryItemDetailsVehicleHistory, details.history);
        break;
    }
  };

  openInventoryDetails = (title, component, data) => {
    this.setState({
      inventoryDetailsTitle: title,
      inventoryDetailsComponent: component,
      inventoryDetailsData: data,
    });
  };

  closeInventoryDetails = () => {
    this.setState({
      inventoryDetailsTitle: undefined,
      inventoryDetailsComponent: undefined,
      inventoryDetailsData: undefined,
    });
  };

  onEndedClosed = () => this.setState({ showEnded: false });

  trackUserAction = (actionId) => {
    const auctionItem = this.props.auctionItemDetails?.details;

    if (actionId === UserAction.VIEW_VDP) {
      Logger.trackUserAction(UserAction.VIEW_VDP, {
        is_auction_staff: AuthService.representsAuction(auctionItem?.auction?.id),
      });
    }
  };

  render() {
    const { inventoryDetailsComponent, inventoryDetailsData, inventoryDetailsTitle, showEnded } = this.state;
    const { auctionItemDetails, location, user, liveLane, timeOffset, refreshList } = this.props;
    const { details, loadedDate, isUpdating, _error } = auctionItemDetails;
    const isPermissionDenied = _error === 'PERMISSION_DENIED';

    if (!loadedDate) {
      return <Empty isAvailable={!isPermissionDenied} onClose={this.clearAuctionItem} showLoader={isUpdating} />;
    }

    const { bidTimeline, topOffer } = details;
    const totalBids = bidTimeline ? bidTimeline.count : 0;
    const uniqueBidders = totalBids === 0 ? [] : uniq(bidTimeline?.list?.map((bid) => bid?.company?.id));

    return (
      <div ref={this.vehicleDetailsRef} className={style.vehicleDetails} data-testid="vehicleDetails">
        {(location.pathname as Route) !== Route.BROWSE && isManuallyControlled(details) ? (
          <AuctionItemDetailsPhysical
            auctionItemDetails={auctionItemDetails?.details}
            clearAuctionItem={this.clearAuctionItem}
            handleFeatureClicked={this.handleFeatureClicked}
            isPermissionDenied={isPermissionDenied}
            isUpdating={isUpdating}
            liveLane={liveLane}
            location={location}
            onEndedClose={this.onEndedClosed}
            refreshList={refreshList}
            showEnded={showEnded}
            topOffer={topOffer}
            totalBids={totalBids}
            uniqueBidders={uniqueBidders.filter(Boolean)}
            user={user}
          >
            <InventoryItemDetailsSlideOut
              component={inventoryDetailsComponent}
              componentData={inventoryDetailsData}
              onClose={this.closeInventoryDetails}
              title={inventoryDetailsTitle}
            />
          </AuctionItemDetailsPhysical>
        ) : (
          <AuctionItemDetailsDigital
            auctionItemDetails={auctionItemDetails?.details}
            clearAuctionItem={this.clearAuctionItem}
            handleFeatureClicked={this.handleFeatureClicked}
            isPermissionDenied={isPermissionDenied}
            isUpdating={isUpdating}
            liveLane={liveLane}
            location={location}
            onEndedClose={this.onEndedClosed}
            refreshList={refreshList}
            showEnded={showEnded}
            timeOffset={timeOffset}
            topOffer={topOffer}
            totalBids={totalBids}
            uniqueBidders={uniqueBidders.filter(Boolean)}
            user={user}
          >
            <InventoryItemDetailsSlideOut
              component={inventoryDetailsComponent}
              componentData={inventoryDetailsData}
              onClose={this.closeInventoryDetails}
              title={inventoryDetailsTitle}
            />
          </AuctionItemDetailsDigital>
        )}
      </div>
    );
  }
}

export default connector(AuctionItemDetailsContainer);
