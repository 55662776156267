import { createAction } from 'redux-actions';

import { getFeatureFlags, getUser, updateUserInfo } from './userApi';
import { processUpdateUser } from 'store/admin/users/details/userDetailsActions';
import { storageManager } from 'utils/storageUtils';

export type CurrentBiddingAsSelections = {
  [auctionId: string]: {
    company: {
      id: string;
      name: string;
    };
    user: {
      id: string;
      name: string;
    };
  };
};

export const userDistanceUnit = storageManager.createOrFetchStorage('distanceUnit');
export const currentBiddingAsSelections = storageManager.createOrFetchStorage('currentBiddingAsSelections');

export const isLoading = createAction('USER_SET_IS_LOADING');
export const isNotLoading = createAction('USER_UNSET_IS_LOADING');
export const isUpdating = createAction('USER_SET_IS_UPDATING');
export const userLoaded = createAction('USER_LOADED');
export const userError = createAction('USER_ERROR');
export const userUpdated = createAction('USER_UPDATED');
export const userCleared = createAction('USER_CLEARED');
export const consignerWhitelistRegionsUpdated = createAction('USER_CONSIGNER_WHITELIST_REGIONS_UPDATED');
export const setCurrentBiddingAsSelection = createAction('USER_SET_CURRENT_BIDDING_AS_SELECTION');
export const userFeatureFlagsUpdated = createAction('USER_FEATURE_FLAGS_UPDATED');

export const getUserData = (dispatch, isReloadingUser = false) => {
  // Prevent clearing user data when refreshing
  dispatch(isReloadingUser ? isUpdating() : isLoading());

  return getUser().then((response) => {
    const formattedResponse = response?.data?.data;
    const distanceUnit = formattedResponse?.user?.distanceUnit;

    dispatch(userLoaded(formattedResponse));
    if (distanceUnit) {
      userDistanceUnit.set(distanceUnit);
    } else {
      userDistanceUnit.remove();
    }
    return formattedResponse;
  });
};

export const processUpdateUserInfo = (options, dispatch) => {
  dispatch(isUpdating());
  return updateUserInfo(options).then((response) => {
    const user = response?.data?.data?.userUpdateInfo;
    processUpdateUser(user, dispatch, options.isModifyingLoggedInUser);
  });
};

export const processSetAndPersistBiddingAsSelection = (options, dispatch) => {
  dispatch(setCurrentBiddingAsSelection(options));

  if (options) {
    currentBiddingAsSelections.set(
      JSON.stringify({
        ...JSON.parse(currentBiddingAsSelections.get() || 'null'),
        ...options,
      })
    );
  } else {
    currentBiddingAsSelections.remove();
  }
};

export const processRefreshFeatureFlags = (dispatch) => {
  return getFeatureFlags()?.then((response) => {
    dispatch(userFeatureFlagsUpdated(response?.data?.data?.featureFlags));
  });
};
