import { debounce } from 'lodash-es';

import BaseClass from 'components/ui/shared/base';
import Checkbox from 'forms/shared/checkbox';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InputText from 'forms/shared/inputText';
import InventoryItem from 'constants/inventoryItem';
import SelectAuction from 'forms/shared/selectAuction';
import SelectCompany, { SelectCompanyOption } from 'forms/shared/selectCompany';
import SelectUser from 'forms/shared/selectUser';
import { AuctionItemFormat, QueryasIsCauseArgs } from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { isQualifiedForAsIs } from 'store/shared/api/graph/queries/asIs';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props {
  /** Error messages. */
  errorMessages?: ErrorMessages;
  /** The inventory item details. */
  inventoryItem: InventoryItem;
  /** True when dialog is open. */
  isOpen: boolean;
  /** True when form is submitting. */
  isSubmitting: boolean;
  /** Function invoked on confirm click. */
  onSubmit: (shouldSubmit, options) => void;
}

interface State {
  /** The selected asIs value. */
  asIs: boolean;
  /** The selected auction id value. */
  auctionId: string | undefined;
  /** The selected company id value. */
  companyId: string | undefined;
  /** Whether is asIs enabled or not. */
  isAsIsEnabled: boolean;
  /** The input price value. */
  price: number | undefined;
  /** The selected user id value. */
  userId: string | undefined;
}

class Dialog extends BaseClass<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      auctionId: undefined,
      companyId: undefined,
      userId: undefined,
      price: undefined,
      asIs: false,
      isAsIsEnabled: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    const { isOpen: isOpenPrev } = prevProps;

    if (isOpenPrev && !isOpen) {
      this.setState({ userId: undefined });
    }
  }

  onPriceChange = debounce(async (value) => {
    const { inventoryItem } = this.props;
    const { auctionId } = this.state;

    const options: QueryasIsCauseArgs = {
      inventoryItemId: inventoryItem?.id,
      auctionId: auctionId ?? '',
      auctionFormat: AuctionItemFormat.GROUNDED,
      price: value,
    };

    const isAsIsEnabled = !!(await isQualifiedForAsIs(options))?.data?.data?.asIsCause;
    this.setState({ isAsIsEnabled });
  }, 250);

  onSubmit = (shouldSubmit) => {
    const { auctionId, companyId, userId, price, asIs } = this.state;
    const { onSubmit } = this.props;

    const options = {
      auctionId,
      companyId,
      userId,
      price,
      asIs,
    };

    onSubmit(shouldSubmit, options);
  };

  render() {
    const { auctionId, companyId, userId, price, asIs, isAsIsEnabled } = this.state;
    const { isOpen, errorMessages, isSubmitting } = this.props;

    const hasError = (name) => errorMessages && errorMessages.find((err) => err.name === name);
    const actionable = !!(auctionId && companyId && userId && price !== undefined && !Number.isNaN(price));

    return (
      <ConfirmDialog
        actionable={actionable}
        actionLabel={t('confirm')}
        actionProgress={isSubmitting}
        isOpen={isOpen}
        onConfirm={this.onSubmit}
        theme="green"
        title={t('direct_sale')}
      >
        <p className={style.instructions}>{t('sell_direct_dialog_message')}</p>
        <fieldset className={style.inputGroup} data-testid="sell-direct-fieldset">
          <SelectAuction onChange={(value: string) => this.setState({ auctionId: value })} />
          <SelectCompany
            id="sellDirectSelectCompany"
            onChange={(company: SelectCompanyOption) => this.setState({ companyId: company?.value, userId: undefined })}
            placeholder={t('select_buyer')}
          />
          <SelectUser
            companyId={companyId}
            id="sellDirectSelectUser"
            isDisabled={!companyId}
            onChange={(user: SelectOption) => this.setState({ userId: user?.value })}
            placeholder={t('select_user')}
          />
          <InputText
            className={style.amount}
            disabled={!(auctionId && companyId && userId)}
            onChange={(value) => {
              const formattedAmount = parseInt(value, 10) || undefined;
              this.setState({ price: formattedAmount });
              this.onPriceChange(formattedAmount);
            }}
            placeholder={t('price')}
            theme={hasError('amount') ? 'error' : undefined}
            type="currency"
          />
          {isAsIsEnabled && (
            <Checkbox
              checked={asIs}
              dataTestId="sold-as-is-checkbox"
              id="sellDirectAsIs"
              onChange={(event) => this.setState({ asIs: event.target.checked })}
            >
              {t('sold_as_is')}
            </Checkbox>
          )}
        </fieldset>
        {errorMessages &&
          errorMessages.map((errorMessage, index) => (
            <p key={`error${index}`} className={style.errorMessage} data-testid="error-messages">
              {errorMessage.message}
            </p>
          ))}
      </ConfirmDialog>
    );
  }
}

export default Dialog;
