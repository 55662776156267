import moment from 'moment/moment';
import { memo, useCallback, useMemo } from 'react';
import { push } from 'react-router-redux';
import { useDispatch } from 'react-redux';

import SystemBanner from 'containers/notifications/systemBanners/systemBanner';
import { AppDispatch } from 'store/configureStore';
import { Route } from 'store/routing/routes';
import {
  USER_SESSION_EXPIRY_STORAGE_NAME,
  UserSessionExpiryInfo,
  setUserSessionExpiryTime,
} from 'containers/notifications/userSessionExpiry/userSessionExpiryHelpers';
import { logoutAndDeleteToken } from 'store/auth/authActions';
import { t } from 'utils/intlUtils';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useLocation } from 'constants/reactRouter';

const UserSessionExpiryBanner = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [state] = useLocalStorage<UserSessionExpiryInfo>(USER_SESSION_EXPIRY_STORAGE_NAME);
  const { pathname } = useLocation();

  const isExpiryDialogVisible = useMemo(() => {
    if (!state || state?.isDismissed || pathname.includes('/auth') || pathname.includes('/register')) {
      return false;
    }

    // Check if expiry time is within the next 3 hours
    const expiryTime = moment.unix(Number(state?.expiryTime));
    const expiryTimeInHours = expiryTime.diff(moment(), 'hours');
    return expiryTimeInHours <= 3;
  }, [pathname, state]);

  /**
   * onSubmit
   */
  const onSubmit = useCallback(() => {
    // Logout
    logoutAndDeleteToken(dispatch, () => dispatch(push(Route.AUTH_LOGIN)));
  }, [dispatch]);

  /**
   * onDismiss
   */
  const onDismiss = useCallback(() => {
    // Mark user session expiry storage entry as dismissed so the user
    // isn't bother by this notification until the next expiry event.
    setUserSessionExpiryTime(state?.expiryTime || '', true);
  }, [state]);

  if (!isExpiryDialogVisible) {
    return null;
  }

  return (
    <SystemBanner
      buttonText={t('reauthenticate')}
      onButtonClick={onSubmit}
      onDismiss={onDismiss}
      text={t('session_expiring_confirm_message')}
      theme="blue"
    />
  );
};

export default memo(UserSessionExpiryBanner);
